import {
  vArray,
  vBoolean,
  vCountry,
  vFormattedPhoneNumber,
  vISODateTime,
  vNonEmptyString,
  vObject,
  vObjectId,
  vOneOf,
  vOptional,
  vOptionalDef,
  vOutput,
  vPicklist,
  vPipe,
  vString,
} from '../validation'

export const carrierLocationStatusTypes = [
  'new', // unprovisioned
  'error', // provisoining returned an error
  'active', // provisioned / can get rates
  'testing', // pending a successful rate before activating
  'none', // no account / cannot get rates
] as const
export type CarrierLocationStatusType =
  (typeof carrierLocationStatusTypes)[number]

export const credentialDetailSchema = vObject({
  type: vNonEmptyString(),
  value: vOneOf([vBoolean(), vString()]),
  expires: vOptional(vPipe(vString(), vISODateTime())),
})
const detailsArraySchema = vArray(credentialDetailSchema)
export const accountCarrierCredentialSchema = vObject({
  details: detailsArraySchema,
})
export const accountCarrierUpdateSchema = vObject({
  salesRepFirstName: vOptional(vString()),
  salesRepLastName: vOptional(vString()),
  salesRepPhone: vOptional(vFormattedPhoneNumber()),
  salesReps: vOptional(
    vArray(
      vObject({
        email: vString(),
      }),
    ),
  ),
  isBeta: vOptional(vBoolean()),
  enableLTL: vOptional(vBoolean()),
  enableParcel: vOptional(vBoolean()),
  enableTL: vOptional(vBoolean()),
  disableProNumbers: vOptional(vBoolean()),
  notified: vOptional(vBoolean()),
  details: vOptional(detailsArraySchema),
})
export type UpsertAccountCarrierDto = vOutput<typeof accountCarrierUpdateSchema>

export type UpdateAccountCarrierCredentialDto = vOutput<
  typeof accountCarrierCredentialSchema
>

export const updateAccountCarrierLocationSchema = vObject({
  status: vPicklist(carrierLocationStatusTypes),
  details: vOptional(vArray(credentialDetailSchema)),
  overrides: vOptional(
    vObject({
      city: vOptional(vString()),
      postalCode: vOptional(vString()),
    }),
  ),
})
export type UpdateAccountCarrierLocationDto = vOutput<
  typeof updateAccountCarrierLocationSchema
>

export const upsertAccountCarrierWithCarrierId = vObject({
  ...accountCarrierUpdateSchema.entries,
  carrierId: vObjectId(),
  isOnboarding: vOptionalDef(vBoolean(), false),
})

export type UpsertAccountCarrierWithIdDto = vOutput<
  typeof upsertAccountCarrierWithCarrierId
>
export const accountLocationUpsertShema = vObject({
  street: vNonEmptyString(),
  city: vNonEmptyString(),
  state: vNonEmptyString(),
  postalCode: vNonEmptyString(),
  country: vCountry,
})
export type AccountLocationUpsertModel = vOutput<
  typeof accountLocationUpsertShema
>
